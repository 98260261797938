import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  getNotificationListAction,
  updateNotificationReadStatusAction,
} from "../../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../IsLoadingHOC";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    let tData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    this.state = {
      menu: false,
      list: [],
      unreadCount: 0,
      userInfo: tData.userInfo,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }
  componentDidMount() {
    this.loadNotificationList();
  }
  loadNotificationList() {
    this.setState({ ...this.state, list: [], unreadCount: 0 });
    /*
    this.props.setLoading(true);
    
    getNotificationListAction(1, 10)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let data = [];
              let unreadCount = 0;
              for (let i in resp.data.rows) {
                let v = resp.data.rows[i];
                data.push({
                  id: v.ID,
                  message: v.fullMessage,
                  subject: v.subject,
                  isRead: parseInt(v.isRead) === 1 ? "Read" : "Not Read",
                  updatedAt: new Date(v.updatedAt).toLocaleString(),
                });
                if (parseInt(v.isRead) !== 1) {
                  unreadCount++;
                }
              }
              this.setState({
                ...this.state,
                list: data,
                unreadCount: unreadCount,
              });
            }
          }
        }
        this.props.setLoading(false);
      })
      .catch(err => {
        let msg =
          "Something went wrong! Please try again or contact Administrator";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
        this.props.setLoading(false);
      });
      */
  }
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell bx-tada" />
            <span className="badge bg-danger rounded-pill">
              {this.state.unreadCount}
            </span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>
                <div className="col-auto">
                  <Link to="/inbox" className="small">
                    {" "}
                    View All
                  </Link>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {this.state.list.map((v, i) => {
                return (
                  <Link
                    key={`notification-${v.id}`}
                    to="/inbox"
                    className="text-reset notification-item"
                  >
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-success rounded-circle font-size-16">
                          <i className="bx bx-badge-check" />
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">{v.subject}</h6>
                        <div className="font-size-12 text-muted">
                          <p
                            className="mb-1"
                            style={{ overflow: "hidden", height: "15px" }}
                            dangerouslySetInnerHTML={{ __html: v.message }}
                          />
                          <Link to="/inbox" className="small">
                            {" "}
                            ....View more
                          </Link>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" />{" "}
                            {v.updatedAt}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </SimpleBar>
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 text-center"
                to="/inbox"
              >
                <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                <span key="t-view-more">{this.props.t("View More..")}</span>
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(
  IsLoadingHOC(NotificationDropdown, "Wait .....")
);

import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Modal, Spinner } from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { getSideMenuListAction } from "../../store/actions";
import { toast } from "react-toastify";
import { parseJwt } from "../../helpers/common_helper";
class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
    let tData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const tokenValue = parseJwt(tData.accessToken);
    this.state = {
      tokenValue: tokenValue,
      show: false,
      partnerList: [],
      siteList: [],
      partner: tokenValue.partnerId,
      site: tokenValue.siteId,
      loader: false,
      userInfo: tData.userInfo,
      sideMenuList: localStorage.getItem("sideMenu")
        ? JSON.parse(localStorage.getItem("sideMenu"))
        : [],
    };
  }
  componentDidMount() {
    if (
      !localStorage.getItem("sideMenu") ||
      !JSON.parse(localStorage.getItem("sideMenu"))
    ) {
      this.loadSideMenuList();
    }
    this.initMenu();
  }
  removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "partner") {
      this.setState({ ...this.state, [name]: value });
      this.loadWebsite(value);
    } else {
      this.setState({ ...this.state, [name]: value });
    }
  };
  loadSideMenuList = () => {
    localStorage.setItem("sideMenu", "[]");
    this.props.setLoading(true);
    getSideMenuListAction()
      .then(resp => {
        // this.props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            this.props.setLoading(false);
            let respData = resp.data;
            if (respData.rows) {
              let d = respData.rows.map((v, i) => {
                return {
                  id: v.ID,
                  name: v.name,
                  route: `${v.route}`,
                  subMenu: v.subMenu,
                  icon: `${v.icon}`,
                };
              });
              localStorage.setItem("sideMenu", JSON.stringify(d));
              this.setState({ ...this.state, sideMenuList: d });
            } else {
              this.setState({ ...this.state, sideMenuList: [] });
            }
          } else {
            localStorage.setItem("sideMenu", "[]");
            this.setState({ ...this.state, sideMenuList: [] });
          }
        } else {
          this.props.setLoading(false);
          localStorage.setItem("sideMenu", "[]");
          this.setState({ ...this.state, sideMenuList: [] });
        }
      })
      .catch(err => {
        this.props.setLoading(false);
        localStorage.setItem("sideMenu", "[]");
        this.setState({ ...this.state, sideMenuList: [] });
      });
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }
  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="#">
                  <span style={{ color: "#a6b0cf", cursor: "default" }}>
                    <b>LogedIn:</b>{" "}
                    {`${
                      this.state.userInfo
                        ? this.state.userInfo.firstName
                        : " N/A"
                    }`}
                  </span>
                  <br />
                </Link>
              </li>
              <hr />
              {this.state.sideMenuList.map((v, i) => {
                return (
                  <li key={`menu-${i}`}>
                    <Link
                      to={v.route}
                      className={v.subMenu.length > 0 ? "has-arrow" : ""}
                    >
                      <i className={`${v.icon}`} />
                      <span>{this.props.t(v.name)}</span>
                    </Link>
                    {v.subMenu.length > 0 ? (
                      <ul className="sub-menu" aria-expanded="false">
                        {v.subMenu.map((v2, i2) => {
                          return (
                            <li key={`menu-${i}-sub-${i2}`}>
                              <Link to={v2.route}>{this.props.t(v2.name)}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(
  withTranslation()(IsLoadingHOC(SidebarContent, "Wait ....."))
);

//import { GET_TASKS, GET_TASKS_FAIL, GET_TASKS_SUCCESS } from "./actionTypes"
import { get, post, put } from "../../helpers/api_helper";
export const getDealerListAction = (pageno = 1, limit = 10) => {
  return get(`/dealer?page=${pageno}&limit=${limit}`);
};
export const getDealerDetailsAction = id => {
  return get(`/dealer/${id}`);
};
export const saveDealerDetailsAction = (method = "post", data, id) => {
  if (method === "post") {
    return post(`/dealer`, data);
  } else {
    return put(`/dealer/${id}`, data);
  }
};

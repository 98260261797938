import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import profileImg from "../../assets/images/profile-img.png";
import { parseJwt } from "../../helpers/common_helper";
const WelcomeComp = () => {
  const [userInfo, setUserInfo] = React.useState({});
  React.useEffect(() => {
    let tData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    if (tData) {
      const tokenValue = parseJwt(tData.accessToken);
      setUserInfo(tokenValue);
    }
  }, [localStorage.getItem("authUser")]);
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Admin Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src="/public/Avatar.jpg"
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                {userInfo.userName}
              </h5>
              <p className="text-muted mb-0 text-truncate">
                {userInfo.type === 0 ? "Admin" : "Partner"}
              </p>
            </Col>

            <Col sm="8" className="pt-3">
              <h5 className="font-size-15">{new Date().toLocaleString()}</h5>
              <p className="text-muted mb-0">Last Login</p>
              {/* <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="" className="btn btn-primary  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div> */}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;

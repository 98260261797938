import React, { useState } from "react";
import { BallTriangle } from "react-loader-spinner";

const Loading = props => {
  return (
    <div
      className="loaderHolder"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        opacity: 0.6,
        zIndex: 2500,
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
      }}
    >
      <div className="loaderMain" style={{ textAlign: "center" }}>
        <div style={{ width: "20%", margin: "20% auto" }}>
          <BallTriangle color="red" width={"100%"} />
        </div>
        <div>{props.message}</div>
      </div>
    </div>
  );
};

export const IsLoadingHOC = (WrappedComponent, loadingMessage) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false);

    const setLoadingState = isComponentLoading => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && <Loading message={loadingMessage} />}
        <WrappedComponent {...props} setLoading={setLoadingState} />
      </>
    );
  }
  return HOC;
};

export default IsLoadingHOC;

import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Label, Button } from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import {
  getLookupListAction,
  getVehicalListAction,
  getLoanApplicationDetailsAction,
  saveLoanApplicationDetailsAction,
} from "./../store/actions";
import IsLoadingHOC from "./IsLoadingHOC";
const LoanApplication = props => {
  let initialState = {
    id: props.id,
    applicationCode: "",
    applicantRef: props.personId,
    kyc: {
      incomeProof: [],
      idProof: [],
      addressProof: [],
      empProof: [],
      others: [],
    },
    loanType: "",
    status: 0,
    stage: "NEW",
    requestedAmt: 0,
    approvedAmt: 0,
    disbursedAmt: 0,
    requestedTenure: 0,
    approvedTenure: 0,
    vehicleInfo: "",
    vehicleCompany: "",
    vehicleModel: "",
    dependents: 2,
    companyName: "",
    companyAddress: "",
    companyLandMark: "",
    noOfYearsInCompany: 1,
    occupationType: "",
    referencePersons: [
      { name: "", email: "", phone: "", address: "", relationship: "" },
      { name: "", email: "", phone: "", address: "", relationship: "" },
    ],
  };
  const [lockeupData, setLockeupData] = useState({});
  const [vahicleData, setVahicleData] = useState([]);
  const [vahicalCompData, setVahicalCompData] = useState([]);
  const [vahicalModalData, setVahicalModalData] = useState([]);
  const [vahicalVariantData, setVahicalVariantData] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const handleChange = (name, value, parentKey = null, childInd = -1) => {
    let fData = JSON.parse(JSON.stringify(formData));
    if (parentKey) {
      fData[parentKey][childInd][name] = value;
    } else {
      fData[name] = value;
    }
    setFormData(fData);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const handleFormSubmit = () => {
    if (formData.loanType.length < 3) {
      toast.error(`Please select Loan Type`);
      return false;
    } else if (formData.vehicleCompany.length < 1) {
      toast.error(`Please select vehicle Company`);
      return false;
    } else if (formData.vehicleModel.length < 1) {
      toast.error(`Please select vehicle Model`);
      return false;
    } else if (parseInt(formData.requestedAmt) < 1) {
      toast.error(`Please enter valid required amount`);
      return false;
    } else if (parseInt(formData.requestedTenure) < 1) {
      toast.error(`Please enter valid required tenure`);
      return false;
    } else if (formData.occupationType.length < 1) {
      toast.error(`Please enter valid occupation type`);
      return false;
    } else if (formData.companyName.length < 1) {
      toast.error(`Please enter valid Company name`);
      return false;
    } else if (formData.companyAddress.length < 5) {
      toast.error(`Please enter valid Company address`);
      return false;
    } else if (parseInt(formData.noOfYearsInCompany) < 1) {
      toast.error(`Please enter valid No of years at Current Company`);
      return false;
    } else if (formData.referencePersons[0].name < 1) {
      toast.error(`Please enter valid Reference Person 1 Name`);
      return false;
    } else if (formData.referencePersons[0].address < 1) {
      toast.error(`Please enter valid Reference Person 1 address`);
      return false;
    } else if (formData.referencePersons[0].relationship < 1) {
      toast.error(`Please enter valid Reference Person 1 relationship`);
      return false;
    } else if (
      formData.referencePersons[0].email < 3 ||
      !ValidateEmail(formData.referencePersons[0].email)
    ) {
      toast.error(`Please enter valid Reference Person 1 Email`);
      return false;
    } else if (
      formData.referencePersons[0].phone.length < 9 ||
      formData.referencePersons[0].phone.length > 11 ||
      !phoneRegExp.test(formData.referencePersons[0].phone)
    ) {
      toast.error(`Please enter valid Reference Person 1 Phone number`);
      return false;
    } else if (formData.referencePersons[1].name < 1) {
      toast.error(`Please enter valid Reference Person 2 Name`);
      return false;
    } else if (formData.referencePersons[1].address < 1) {
      toast.error(`Please enter valid Reference Person 2 address`);
      return false;
    } else if (formData.referencePersons[1].relationship < 1) {
      toast.error(`Please enter valid Reference Person 2 relationship`);
      return false;
    } else if (
      formData.referencePersons[1].email < 3 ||
      !ValidateEmail(formData.referencePersons[1].email)
    ) {
      toast.error(`Please enter valid Reference Person 2 Email`);
      return false;
    } else if (
      formData.referencePersons[1].phone.length < 9 ||
      formData.referencePersons[1].phone.length > 11 ||
      !phoneRegExp.test(formData.referencePersons[1].phone)
    ) {
      toast.error(`Please enter valid Reference Person 2 Phone number`);
      return false;
    }
    props.setLoading(true);
    let requestBody = JSON.parse(JSON.stringify(formData));
    if (!requestBody.id) {
      delete requestBody.id;
    }
    if (!requestBody.hasOwnProperty("applicantRef")) {
      requestBody["applicantRef"] = props.personId;
    }
    saveLoanApplicationDetailsAction(
      formData.id ? "put" : "post",
      requestBody,
      formData.id
    )
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          toast.success("Information saved successfully");
          //setFormData(initialState);
          if (props.parentCallBack) {
            props.parentCallBack("loanApplication", resp.data, "saved");
          }
        } else {
          toast.error(
            "Something went wrong! Please try again or contact to Administration Department"
          );
        }
      })
      .catch(err => {
        props.setLoading(false);
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
      });
  };
  const ValidateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    loadLoockupData();
    loadVahicleData();
  }, []);
  const loadLoockupData = () => {
    props.setLoading(true);
    setLockeupData({});
    getLookupListAction("loan-type,occupation,relation,reference-person")
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let lookupData = {};
              for (let i in resp.data.rows) {
                let tages = resp.data.rows[i].tag;
                if (tages) {
                  for (let j in tages) {
                    if (!lookupData.hasOwnProperty(tages[j])) {
                      lookupData[tages[j]] = [];
                    }
                    lookupData[tages[j]].push(resp.data.rows[i]);
                  }
                }
              }
              setLockeupData(lookupData);
            }
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  const loadVahicleData = () => {
    props.setLoading(true);
    getVehicalListAction()
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let comp = [];
            if (Array.isArray(resp.data.rows)) {
              setVahicleData(resp.data.rows);
              for (let i in resp.data.rows) {
                if (comp.indexOf(resp.data.rows[i].manufacturer) < 0) {
                  comp.push(resp.data.rows[i].manufacturer);
                }
              }
            }
            setVahicalCompData(comp);
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  useEffect(() => {
    setVahicalModalData([]);
    setVahicalVariantData([]);
    let arr = vahicleData.filter(
      v => v.manufacturer === formData.vehicleCompany
    );
    let modalArr = [];
    for (let i in arr) {
      if (modalArr.indexOf(arr[i].vehiclemodel) < 0) {
        modalArr.push(arr[i].vehiclemodel);
      }
    }
    setVahicalModalData(modalArr);
  }, [formData.vehicleCompany]);
  useEffect(() => {
    let arr = vahicleData.filter(
      v =>
        v.manufacturer === formData.vehicleCompany &&
        v.vehiclemodel === formData.vehicleModel
    );
    setVahicalVariantData(arr);
  }, [formData.vehicleModel]);
  useEffect(() => {
    if (props.id) {
      LoadLoanApplicationDeatils(props.id);
    }
  }, [props.id]);
  const LoadLoanApplicationDeatils = id => {
    props.setLoading(true);
    getLoanApplicationDetailsAction(id)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respdata = resp.data.rows[0];
            if (props.parentCallBack) {
              props.parentCallBack("loanApplication", respdata, "info");
            }
            let fData = JSON.parse(JSON.stringify(initialState));
            fData.applicationCode = respdata.applicationCode;
            fData.applicantRef = respdata.applicantRef;
            fData.kyc = respdata.kyc;
            fData.loanType = respdata.loanType;
            fData.status = respdata.status;
            fData.stage = respdata.stage;
            fData.requestedAmt = respdata.requestedAmt;
            fData.approvedAmt = respdata.approvedAmt;
            fData.disbursedAmt = respdata.disbursedAmt;
            fData.requestedTenure = respdata.requestedTenure;
            fData.approvedTenure = respdata.approvedTenure;
            fData.vehicleInfo = respdata.vehicleInfo;
            fData.vehicleCompany = respdata.vehicleCompany;
            fData.vehicleModel = respdata.vehicleModel;
            fData.companyName = respdata.companyName;
            fData.companyAddress = respdata.companyAddress;
            fData.companyLandMark = respdata.companyLandMark;
            fData.noOfYearsInCompany = respdata.noOfYearsInCompany;
            fData.occupationType = respdata.occupationType;
            fData.referencePersons = respdata.referencePersons;
            setFormData(fData);
          } else {
            toast.error("Data object is not received");
          }
        } else {
          toast.error("Loan application details not found");
        }
      })
      .catch(err => {
        toast.error("Invalid request");
        props.setLoading(false);
      });
  };
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Loan Application Details</h4>
            <div className="content clearfix mt-4">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  loanType: formData.loanType,
                  requestedAmt: formData.requestedAmt,
                  approvedAmt: formData.approvedAmt,
                  disbursedAmt: formData.disbursedAmt,
                  requestedTenure: formData.requestedTenure,
                  approvedTenure: formData.approvedTenure,
                  vehicleInfo: formData.vehicleInfo,
                  vehicleCompany: formData.vehicleCompany,
                  vehicleModel: formData.vehicleModel,
                  dependents: formData.dependents,
                  companyName: formData.companyName,
                  companyAddress: formData.companyAddress,
                  companyLandMark: formData.companyLandMark,
                  occupationType: formData.occupationType,
                  noOfYearsInCompany: formData.noOfYearsInCompany,
                  referencePersonsName1: formData.referencePersons[0].name,
                  referencePersonsEmail1: formData.referencePersons[0].email,
                  referencePersonsPhone1: formData.referencePersons[0].phone,
                  referencePersonsAddress1:
                    formData.referencePersons[0].address,
                  referencePersonsRelationship1:
                    formData.referencePersons[0].relationship,
                  referencePersonsName2: formData.referencePersons[1].name,
                  referencePersonsEmail2: formData.referencePersons[1].email,
                  referencePersonsPhone2: formData.referencePersons[1].phone,
                  referencePersonsAddress2:
                    formData.referencePersons[1].address,
                  referencePersonsRelationship2:
                    formData.referencePersons[1].relationship,
                }}
                validationSchema={Yup.object().shape({
                  referencePersonsName1: Yup.string().required(
                    "Please Enter Dealer Name"
                  ),
                  referencePersonsEmail1: Yup.string()
                    .email("Please enter a valid Email")
                    .required("Please enter a valid Email Address"),
                  referencePersonsPhone1: Yup.string()
                    .matches(phoneRegExp, "Please enter a valid Phone Number")
                    .required("Please enter a valid Phone Number"),
                  referencePersonsName1: Yup.string().required(
                    "Please Enter Dealer Name"
                  ),
                  referencePersonsEmail2: Yup.string()
                    .email("Please enter a valid Email")
                    .required("Please enter a valid Email Address"),
                  referencePersonsPhone2: Yup.string()
                    .matches(phoneRegExp, "Please enter a valid Phone Number")
                    .required("Please enter a valid Phone Number"),
                })}
                onSubmit={values => {
                  //props.editProfile(values);
                }}
              >
                {({ errors, status, touched }) => (
                  <Form className="form-horizontal">
                    <Row md={3} lg={3} sm={1} xs={1}>
                      <Col>
                        <Label for="ame" className="form-label">
                          Loan Type *
                        </Label>
                        <Field
                          as="select"
                          name="loanType"
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          values={formData.loanType}
                          disabled={props.editDisabled}
                          className={
                            "form-control" +
                            (errors.loanType && touched.loanType
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">--Select Loan Type--</option>
                          {lockeupData["loan-type"]
                            ? lockeupData["loan-type"].map((v, i) => {
                                return (
                                  <option key={`loan-type-${i}`} value={v.id}>
                                    {v.label}
                                  </option>
                                );
                              })
                            : ""}
                        </Field>
                      </Col>
                      {formData.loanType ? (
                        <>
                          <Col>
                            <Label for="ame" className="form-label">
                              Vehical Company *
                            </Label>
                            <Field
                              as="select"
                              name="vehicleCompany"
                              disabled={props.editDisabled}
                              values={formData.vehicleCompany}
                              onChange={e => {
                                handleChange(e.target.name, e.target.value);
                              }}
                              className={
                                "form-control" +
                                (errors.vehicleCompany && touched.vehicleCompany
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="">
                                --Select Vehical Company--
                              </option>
                              {vahicalCompData.map((v, i) => {
                                return (
                                  <option key={`v-com-${i}`} value={v}>
                                    {v}
                                  </option>
                                );
                              })}
                            </Field>
                          </Col>
                          <Col>
                            <Label for="ame" className="form-label">
                              Vehical Modal *
                            </Label>
                            <Field
                              as="select"
                              name="vehicleModel"
                              disabled={props.editDisabled}
                              values={formData.vehicleModel}
                              onChange={e => {
                                handleChange(e.target.name, e.target.value);
                              }}
                              className={
                                "form-control" +
                                (errors.vehicleModel && touched.vehicleModel
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="">--Select Vehical Modal--</option>
                              {vahicalModalData.map((v, i) => {
                                return (
                                  <option key={`v-mod-${i}`} value={v}>
                                    {v}
                                  </option>
                                );
                              })}
                            </Field>
                          </Col>
                          <Col>
                            <Label for="ame" className="form-label">
                              Vehical Varient *
                            </Label>
                            <Field
                              as="select"
                              name="vehicleInfo"
                              disabled={props.editDisabled}
                              values={formData.vehicleInfo}
                              onChange={e => {
                                handleChange(e.target.name, e.target.value);
                              }}
                              className={
                                "form-control" +
                                (errors.vehicleInfo && touched.vehicleInfo
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="">--Select Variant--</option>
                              {vahicalVariantData.map((v, i) => {
                                return (
                                  <option key={`v-veri-${i}`} value={v.id}>
                                    {v.variant}
                                  </option>
                                );
                              })}
                            </Field>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Required Amount *
                        </Label>
                        <Field
                          name="requestedAmt"
                          type="number"
                          maxLength="10"
                          values={formData.requestedAmt}
                          placeholder="Required Amount"
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.requestedAmt && touched.requestedAmt
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Loan Tenure (In month) *
                        </Label>
                        <Field
                          name="requestedTenure"
                          type="number"
                          maxLength="3"
                          values={formData.requestedTenure}
                          placeholder="Loan Tenure (In month)"
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.requestedTenure && touched.requestedTenure
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                    </Row>
                    <h5
                      style={{ borderBottom: "1px solid #ccc" }}
                      className="mt-4 mb-2"
                    >
                      Occupation Details
                    </h5>
                    <Row md={3} lg={3} sm={1} xs={1}>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Occupation Type *
                        </Label>
                        <Field
                          as="select"
                          name="occupationType"
                          onChange={e => {
                            handleChange("occupationType", e.target.value);
                          }}
                          values={formData.occupationType}
                          disabled={props.editDisabled}
                          className={
                            "form-control" +
                            (errors.occupationType && touched.occupationType
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">--Select Occupation--</option>
                          {lockeupData["occupation"]
                            ? lockeupData["occupation"].map((v, i) => {
                                return (
                                  <option key={`occupations-${i}`} value={v.id}>
                                    {v.label}
                                  </option>
                                );
                              })
                            : ""}
                        </Field>
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Company Name *
                        </Label>
                        <Field
                          name="companyName"
                          type="text"
                          placeholder="Company Name"
                          values={formData.companyName}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.companyName && touched.companyName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Company Address *
                        </Label>
                        <Field
                          name="companyAddress"
                          type="text"
                          placeholder="Address Line 2 "
                          values={formData.companyAddress}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.companyAddress && touched.companyAddress
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="companyAddress"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Landmark
                        </Label>
                        <Field
                          name="companyLandMark"
                          type="text"
                          placeholder="Landmark "
                          values={formData.companyLandMark}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.companyLandMark && touched.companyLandMark
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="companyLandMark"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>

                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          No of years at Current Company *
                        </Label>
                        <Field
                          name="noOfYearsInCompany"
                          type="number"
                          placeholder="No of years at Current address"
                          values={formData.noOfYearsInCompany}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          className={
                            "form-control" +
                            (errors.noOfYearsInCompany &&
                            touched.noOfYearsInCompany
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="noOfYearsInCompany"
                          component="div"
                          className="invalid-feedback"
                        />
                      </Col>
                    </Row>
                    <h5
                      style={{ borderBottom: "1px solid #ccc" }}
                      className="mt-4 mb-2"
                    >
                      Refrence 1 Details
                    </h5>
                    <Row md={3} lg={3} sm={1} xs={1}>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Name *
                        </Label>
                        <Field
                          name="referencePersonsName1"
                          values={formData.referencePersons[0].name}
                          disabled={props.editDisabled}
                          placeholder="Name"
                          onChange={e => {
                            handleChange(
                              "name",
                              e.target.value,
                              "referencePersons",
                              0
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsName1 &&
                            touched.referencePersonsName1
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Eamil *
                        </Label>
                        <Field
                          name="referencePersonsEmail1"
                          values={formData.referencePersons[0].email}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(
                              "email",
                              e.target.value,
                              "referencePersons",
                              0
                            );
                          }}
                          placeholder="Email"
                          className={
                            "form-control" +
                            (errors.referencePersonsEmail1 &&
                            touched.referencePersonsEmail1
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Phone No *
                        </Label>
                        <Field
                          name="referencePersonsPhone1"
                          values={formData.referencePersons[0].phone}
                          disabled={props.editDisabled}
                          placeholder="Phone No"
                          onChange={e => {
                            handleChange(
                              "phone",
                              e.target.value,
                              "referencePersons",
                              0
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsPhone1 &&
                            touched.referencePersonsPhone1
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Address *
                        </Label>
                        <Field
                          name="referencePersonsAddress1"
                          values={formData.referencePersons[0].address}
                          disabled={props.editDisabled}
                          placeholder="Address"
                          onChange={e => {
                            handleChange(
                              "address",
                              e.target.value,
                              "referencePersons",
                              0
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsAddress1 &&
                            touched.referencePersonsAddress1
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Relation Ship *
                        </Label>
                        <Field
                          as="select"
                          name="referencePersonsRelationship1"
                          values={formData.referencePersons[0].relationship}
                          onChange={e => {
                            handleChange(
                              "relationship",
                              e.target.value,
                              "referencePersons",
                              0
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsRelationship1 &&
                            touched.referencePersonsRelationship1
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">--Select Relation Ship--</option>
                          {lockeupData["reference-person"]
                            ? lockeupData["reference-person"].map((v, i) => {
                                return (
                                  <option
                                    key={`reference-person-${i}`}
                                    value={v.id}
                                  >
                                    {v.label}
                                  </option>
                                );
                              })
                            : ""}
                        </Field>
                      </Col>
                    </Row>
                    <h5
                      style={{ borderBottom: "1px solid #ccc" }}
                      className="mt-4 mb-2"
                    >
                      Refrence 2 Details
                    </h5>
                    <Row md={3} lg={3} sm={1} xs={1}>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Name *
                        </Label>
                        <Field
                          name="referencePersonsName2"
                          values={formData.referencePersons[1].name}
                          disabled={props.editDisabled}
                          placeholder="Name"
                          onChange={e => {
                            handleChange(
                              "name",
                              e.target.value,
                              "referencePersons",
                              1
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsName2 &&
                            touched.referencePersonsName2
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Eamil *
                        </Label>
                        <Field
                          name="referencePersonsEmail2"
                          values={formData.referencePersons[1].email}
                          disabled={props.editDisabled}
                          onChange={e => {
                            handleChange(
                              "email",
                              e.target.value,
                              "referencePersons",
                              1
                            );
                          }}
                          placeholder="Email"
                          className={
                            "form-control" +
                            (errors.referencePersonsEmail2 &&
                            touched.referencePersonsEmail2
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Phone No *
                        </Label>
                        <Field
                          name="referencePersonsPhone2"
                          values={formData.referencePersons[1].phone}
                          disabled={props.editDisabled}
                          placeholder="Phone No"
                          onChange={e => {
                            handleChange(
                              "phone",
                              e.target.value,
                              "referencePersons",
                              1
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsPhone2 &&
                            touched.referencePersonsPhone2
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Address *
                        </Label>
                        <Field
                          name="referencePersonsAddress2"
                          values={formData.referencePersons[1].address}
                          disabled={props.editDisabled}
                          placeholder="Phone No"
                          onChange={e => {
                            handleChange(
                              "address",
                              e.target.value,
                              "referencePersons",
                              1
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsAddress2 &&
                            touched.referencePersonsAddress2
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </Col>
                      <Col className="pt-1">
                        <Label for="ame" className="form-label">
                          Relation Ship *
                        </Label>
                        <Field
                          as="select"
                          name="referencePersonsRelationship2"
                          value={formData.referencePersons[1].relationship}
                          onChange={e => {
                            handleChange(
                              "relationship",
                              e.target.value,
                              "referencePersons",
                              1
                            );
                          }}
                          className={
                            "form-control" +
                            (errors.referencePersonsRelationship2 &&
                            touched.referencePersonsRelationship2
                              ? " is-invalid"
                              : "")
                          }
                        >
                          <option value="">--Select Relation Ship--</option>
                          {lockeupData["reference-person"]
                            ? lockeupData["reference-person"].map((v, i) => {
                                return (
                                  <option
                                    key={`reference-person-${i}`}
                                    value={v.id}
                                  >
                                    {v.label}
                                  </option>
                                );
                              })
                            : ""}
                        </Field>
                      </Col>
                    </Row>
                    {!props.editDisabled ? (
                      <Row md={1}>
                        <Col className="text-center pt-2">
                          <Button
                            disabled={props.editDisabled}
                            variant="success"
                            className="btn btn-success"
                            onClick={handleFormSubmit}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default IsLoadingHOC(LoanApplication, "Wait .....");

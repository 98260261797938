import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import TicketDetails from "./TicketDetails";
import DatatableTables from "../../components/Tables/DatatableTables";
import { getTicketListAction } from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link, useParams } from "react-router-dom";
import {
  TICKET_STATUS_LIST,
  TICKET_PRIORITY_LIST,
} from "../../constants/config";
import NewTicket from "./NewTicket";
const Support = props => {
  const [columns, setColumns] = useState([]);
  const [listData, setListData] = useState([]);
  const { type, id } = useParams();
  const pageNo = 1,
    pageLimit = 1000;
  useEffect(() => {
    loadTicketList();
  }, []);

  const loadTicketList = () => {
    setListData([]);
    setColumns([]);
    props.setLoading(true);
    getTicketListAction(pageNo, pageLimit)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let data = resp.data.rows.map(v => ({
                id: v.code,
                name: v.subject,
                code: v.code.toUpperCase(),
                category: v.catgName,
                status: TICKET_STATUS_LIST[v.status],
                priority: TICKET_PRIORITY_LIST[v.priority],
                updatedAt: new Date(v.updatedAt).toLocaleString(),
              }));
              setListData(data);
              setColumns([
                {
                  dataField: "code",
                  text: "Ticket No",
                  sort: true,
                },
                {
                  dataField: "category",
                  text: "Category",
                  sort: true,
                },
                {
                  dataField: "priority",
                  text: "Priority",
                  sort: true,
                },
                {
                  dataField: "name",
                  text: "Subject",
                  sort: true,
                },
                {
                  dataField: "status",
                  text: "Status",
                  sort: true,
                },
                {
                  dataField: "updatedAt",
                  text: "Last Activity",
                  sort: true,
                },
                {
                  dataField: "id",
                  text: "Action",
                  formatter: ActionButtons,
                  sort: false,
                },
              ]);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        let msg =
          "Something went wrong! Please try again or contact Administrator";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
        console.log("errerr", JSON.stringify(err.response.data));
        props.setLoading(false);
      });
  };
  const ActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Link
        title="Edit"
        className="text-success fa fa-edit"
        to={`/support/details/${row.id}`}
      />
    );
  };
  if (type === "details") {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>LoanPeCar Admin Portal Support Center</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col className="col-12">
                <TicketDetails id={id} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  } else if (type === "new") {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>LoanPeCar Admin Portal Support Center</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col className="col-12">
                <NewTicket id={id} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>LoanPeCar Admin Support Center</title>
          </MetaTags>

          <Container fluid>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      Ticket List
                      <Link
                        to="/support/new"
                        className="btn btn-success"
                        style={{ float: "right" }}
                      >
                        Add New Ticket
                      </Link>
                    </CardTitle>
                    {columns.length > 0 ? (
                      <DatatableTables columns={columns} data={listData} />
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
};

export default IsLoadingHOC(Support, "Wait .....");

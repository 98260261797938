import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import {
  getReportStructureAction,
  postReportDataAction,
} from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link, useParams } from "react-router-dom";
import FormElement from "../../components/FormElement";
const Reports = props => {
  const { id } = useParams();
  const reportDivId = `report-${id}`;
  const [reportInfo, setReportInfo] = useState({ name: "Reports" });
  const [inputParam, setInputParam] = useState([]);
  const [reportData, setReportData] = useState({});
  const [showFilter, setShowFilter] = useState(true);
  useEffect(() => {
    getReportStructure();
  }, []);

  const getReportStructure = () => {
    props.setLoading(true);
    getReportStructureAction(id)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            setReportInfo(resp.data);
            if (resp.data.inputCriteria) {
              let d = resp.data.inputCriteria.map(v => {
                return { ...v, value: "" };
              });
              setInputParam(d);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        props.setLoading(false);
        console.log("error", err);
        toast.error("Invalid Report");
      });
  };
  const handleGetDataButtonClick = () => {
    let reqInputs = inputParam.filter(v => {
      return v.required && !v.value;
    });
    if (reqInputs.length > 0) {
      for (let i in reqInputs) {
        toast.error(`Invalid ${reqInputs[i].displayName}`);
      }
      return false;
    }
    props.setLoading(true);
    postReportDataAction(id, inputParam)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (resp.data.reportDef) {
              resp.data.reportDef.container = reportDivId;
              let pivot = new window.WebDataRocks(resp.data.reportDef);
              setShowFilter(false);
            }
            setReportData(resp.data);
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        props.setLoading(false);
        console.log("error", err);
        toast.error("Invalid Report");
      });
  };
  const handleChangeReportFilter = (eleID, value, index) => {
    let fd = JSON.parse(JSON.stringify(inputParam));
    fd[index].value = value;
    setInputParam(fd);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>LoanPeCar {reportInfo.name}</title>
        </MetaTags>
        <Container fluid>
          <Row md={1} xs={1}>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {reportInfo.name}{" "}
                    <Link
                      title="Hide/Show Filter"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      {" "}
                      <i
                        className={
                          showFilter ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></i>{" "}
                    </Link>
                  </CardTitle>
                  {showFilter ? (
                    <Row md={3} xs={1}>
                      {inputParam.map((v, i) => {
                        return (
                          <FormElement
                            parentCallback={handleChangeReportFilter}
                            index={i}
                            element={v}
                            key={`formElement-${i}`}
                          />
                        );
                      })}
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row md={1} xs={1}>
                    {showFilter ? (
                      <Col className="text-center pt-2">
                        <Button
                          type="button"
                          onClick={handleGetDataButtonClick}
                          color="success"
                          className="btn "
                        >
                          Get Data
                        </Button>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col id={reportDivId} className="pt-2"></Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IsLoadingHOC(Reports, "Wait .....");

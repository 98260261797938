import React from "react";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Col, Label, InputGroup, Input } from "reactstrap";
const FormElement = props => {
  const handleChange = (eleID, value) => {
    props.parentCallback(eleID, value, props.index);
  };
  if (props.element.inputType === "datepicker") {
    return (
      <Col className="pt-1">
        <Label for="ame" className="form-label">
          {props.element.displayName}
          {props.element.required ? "*" : ""}
        </Label>
        <InputGroup>
          <Flatpickr
            className="form-control d-block"
            placeholder="dd M,yyyy"
            name={props.element.inputName}
            onChange={e => {
              handleChange(
                props.element.inputName,
                `${e[0].getFullYear()}-${e[0].getMonth() + 1 > 9 ? "" : "0"}${
                  e[0].getMonth() + 1
                }-${e[0].getDate() > 9 ? "" : "0"}${e[0].getDate()}`
              );
            }}
            value={props.element.value}
            options={{
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
            }}
          />
        </InputGroup>
      </Col>
    );
  }
  if (props.element.inputType === "datetimepicker") {
    return (
      <Col className="pt-1">
        <Label for="ame" className="form-label">
          {props.element.displayName}
          {props.element.required ? "*" : ""}
        </Label>
        <InputGroup>
          <Flatpickr
            className="form-control d-block"
            placeholder="dd M,yyyy"
            name={props.element.inputName}
            onChange={e => {
              handleChange(
                props.element.inputName,
                `${e[0].getFullYear()}-${e[0].getMonth() + 1 > 9 ? "" : "0"}${
                  e[0].getMonth() + 1
                }-${e[0].getDate() > 9 ? "" : "0"}${e[0].getDate()}`
              );
            }}
            value={props.element.value}
            options={{
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
            }}
          />
        </InputGroup>
      </Col>
    );
  }
  if (props.element.inputType === "dropdown") {
    return (
      <Col className="pt-1">
        <Label for="ame" className="form-label">
          {props.element.displayName}
          {props.element.required ? "*" : ""}
        </Label>
        <select
          id={props.element.inputName}
          name={props.element.inputName}
          className="form-select select2"
          onChange={e => {
            handleChange(e.target.name, e.target.value);
          }}
          value={props.element.value}
          multiple={props.element.selectMultiple}
        >
          <option value="">---Select {props.element.displayName}---</option>
          {props.element.inputOptions.map((v, i) => {
            return (
              <option
                key={`drop-${props.element.inputName}-${i}`}
                value={v.value}
              >
                {v.label}
              </option>
            );
          })}
        </select>
      </Col>
    );
  } else {
    return (
      <Col className="pt-1">
        <Label for="ame" className="form-label">
          {props.element.displayName}
          {props.element.required ? "*" : ""}
        </Label>
        <Input
          id={props.element.inputName}
          name={props.element.inputName}
          type={props.element.inputType}
          onChange={e => {
            handleChange(e.target.name, e.target.value);
          }}
          value={props.element.value}
          maxLength={100}
          placeholder={props.element.displayName}
          className="form-control"
        />
      </Col>
    );
  }
};
export default FormElement;

import axios from "axios";
import store from "../store";
//import accessToken from "./jwt-token-access/accessToken";

//apply base url for axios
const API_URL = process.env.REACT_APP_API_BASE_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

//axiosApi.defaults.headers.common["Authorization"] = token;
axiosApi.interceptors.request.use(
  config => {
    let localData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    let accessToken = localData.accessToken ? localData.accessToken : "";
    accessToken =
      !accessToken && localData.token ? localData.token : accessToken;
    //console.log("localData", localData, accessToken);
    if (accessToken) {
      config.headers.common["Authorization"] = `bearer ${accessToken}`;
    }
    console.log("config.headers", config.headers);
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let localData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const refreshToken = localData.refreshToken ? localData.refreshToken : "";
    if (originalRequest.url !== `${API_URL}/login` && error.response) {
      if (
        refreshToken &&
        error.response.data.status === -1 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        axiosApi
          .post(`${baseUrl}/refresh-token`, { refreshToken: refreshToken })
          .then(res => {
            if (res["data"]) {
              if (res.data.status === 1) {
                store.dispatch(saveAccessToken(res.data.data));
                return axios(originalRequest);
              }
            } else {
              console.log("Refresh token Response", res);
              //store.dispatch({ type: "LOG_OUT" });
            }
          })
          .catch(error2 => {
            console.log("Refresh Token expire", error2);
            //store.dispatch({ type: "LOG_OUT" });
            return Promise.reject(error2);
          });
      }
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(error => Promise.reject(error));
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    return response.data;
  });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}

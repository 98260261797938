import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  InputGroup,
  Button,
  Input,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import SwitchUI from "components/SwitchUI";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import {
  getCountryListAction,
  getStateListAction,
  getCityListAction,
  saveDealerDetailsAction,
  getDealerDetailsAction,
} from "../../store/actions";
import Select from "react-select";
import DocumentUpload from "../../components/DocumentUpload";
const AddEditDealer = props => {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const history = useHistory();
  const toggleTab = tab => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };
  let initialState = {
    id: props.id,
    name: "",
    phone: "",
    email: "",
    remarks: "",
    docsRef: [],
    bankDetails: {
      bankName: "",
      acName: "",
      acNumber: "",
      IFSC: "",
      branch: "",
      city: "",
    },
    address: {
      address1: "",
      address2: "",
      address3: "",
      city: 0,
      state: 0,
      country: 0,
      postalCode: "",
    },
    contactPerson: [
      {
        name: "",
        phone: "",
        email: "",
      },
    ],
    status: 1,
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (props.id) {
      LoadDealerDeatils(props.id);
    }
  }, [props.id]);
  const LoadDealerDeatils = id => {
    props.setLoading(true);
    getDealerDetailsAction(id)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let respdata = resp.data.rows[0];
            let fData = JSON.parse(JSON.stringify(initialState));
            fData.name = respdata.name;
            fData.phone = respdata.phone;
            fData.email = respdata.email;
            fData.docsRef = respdata.docsRef;
            fData.bankDetails = respdata.bankDetails;
            fData.address = respdata.address;
            fData.contactPerson = respdata.contactPerson;
            fData.status = respdata.status;
            fData.remarks = respdata.remarks;
            setFormData(fData);
          } else {
            toast.error("Invalid request");
            history.push("/dealer");
          }
        } else {
          toast.error("Invalid request");
          history.push("/dealer");
        }
      })
      .catch(err => {
        toast.error("Invalid request");
        history.push("/dealer");
        props.setLoading(false);
      });
  };
  useEffect(() => {
    loadCountryList();
  }, []);
  const loadCountryList = () => {
    props.setLoading(true);
    setCountryList([]);
    getCountryListAction()
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let data = [];
            if (Array.isArray(resp.data.rows)) {
              data = resp.data.rows;
            } else if (Array.isArray(resp.data)) {
              data = resp.data;
            }
            setCountryList(
              data.map((v, i) => ({
                value: v.ID,
                label: `${v.CountryName}(${v.AlphaCode3})`,
              }))
            );
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  useEffect(() => {
    loadStateList();
  }, [formData.address.countryId]);
  const loadStateList = () => {
    props.setLoading(true);
    setStateList([]);
    setCityList([]);
    if (formData.address.countryId) {
      getStateListAction(formData.address.countryId)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            if (resp.data) {
              let data = [];
              if (Array.isArray(resp.data.rows)) {
                data = resp.data.rows;
              } else if (Array.isArray(resp.data)) {
                data = resp.data;
              }
              setStateList(
                data.map((v, i) => ({
                  value: v.ID,
                  label: v.StateName,
                }))
              );
            }
          }
        })
        .catch(err => {
          props.setLoading(false);
        });
    }
  };
  useEffect(() => {
    loadCityList();
  }, [formData.address.stateId]);
  const loadCityList = () => {
    props.setLoading(true);
    setCityList([]);
    if (formData.address.stateId) {
      getCityListAction(formData.address.stateId)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            if (resp.data) {
              let data = [];
              if (Array.isArray(resp.data.rows)) {
                data = resp.data.rows;
              } else if (Array.isArray(resp.data)) {
                data = resp.data;
              }
              setCityList(
                data.map((v, i) => ({
                  value: v.ID,
                  label: v.CityName,
                }))
              );
            }
          }
        })
        .catch(err => {
          props.setLoading(false);
        });
    }
  };
  const handleChange = (name, value, parentKey = null, childInd = -1) => {
    let fData = JSON.parse(JSON.stringify(formData));
    if (parentKey) {
      if (childInd < 0) {
        fData[parentKey][name] = value;
        if (parentKey === "address") {
          if (name === "countryId") {
            fData[parentKey].stateId = 0;
            fData[parentKey].cityId = 0;
          } else if (name === "stateId") {
            fData[parentKey].cityId = 0;
          }
        }
      } else {
        fData[parentKey][childInd][name] = value;
      }
    } else {
      fData[name] = value;
    }
    console.log(".........", fData);
    setFormData(fData);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const handleFormSubmitOfDealer = () => {
    if (formData.name.length < 3) {
      toast.error(`Please enter a Valid Dealer name`);
      return false;
    } else if (formData.email.length < 3 || !ValidateEmail(formData.email)) {
      toast.error(`Please enter a Valid Dealer Email address`);
      return false;
    } else if (
      formData.phone.length < 9 ||
      formData.phone.length > 11 ||
      !phoneRegExp.test(formData.phone)
    ) {
      toast.error(`Please enter a Valid Dealer Phone number`);
      return false;
    } else if (!formData.address.countryId) {
      toast.error(`Please select Dealer Country`);
      return false;
    } else if (!formData.address.stateId) {
      toast.error(`Please select Dealer State`);
      return false;
    } else if (!formData.address.cityId) {
      toast.error(`Please select Dealer City`);
      return false;
    } else if (formData.address.postalCode.length !== 6) {
      toast.error(`Please enter a Valid Postal Code`);
      return false;
    } else if (formData.address.address1.length < 2) {
      toast.error(`Please enter a Valid Address`);
      return false;
    }
    let errorStatus = false;
    for (let i in formData.contactPerson) {
      if (formData.contactPerson[i].name.length < 1) {
        toast.error(`Please enter Contact Person Name`);
        errorStatus = true;
      }
      if (
        formData.contactPerson[i].email.length < 3 ||
        !ValidateEmail(formData.contactPerson[i].email)
      ) {
        toast.error(`Please enter Contact Person EmailID`);
        errorStatus = true;
      }
      if (formData.contactPerson[i].phone.length < 9) {
        toast.error(`Please enter Contact Person valid Phone Number`);
        errorStatus = true;
      }
    }
    props.setLoading(true);
    let requestBody = JSON.parse(JSON.stringify(formData));
    if (!requestBody.id) {
      delete requestBody.id;
    }
    delete requestBody.docsRef;
    saveDealerDetailsAction(
      formData.id ? "put" : "post",
      requestBody,
      formData.id
    )
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          toast.success("Dealer Information saved successfully");
          setFormData(initialState);
          history.push("/dealer");
        } else {
          toast.error(
            "Something went wrong! Please try again or contact to Administration Department"
          );
        }
      })
      .catch(err => {
        props.setLoading(false);
        let msg =
          "Something went wrong! Please try again or contact to Administration Department";
        if (err.response) {
          if (err.response.data) {
            if (Array.isArray(err.response.data.errors)) {
              msg = "";
              for (let i in err.response.data.errors) {
                msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
              }
            }
          }
        }
        toast.error(msg);
      });
  };
  const ValidateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  const addDelCp = (op, ind) => {
    let d = JSON.parse(JSON.stringify(formData));
    if (op === "add") {
      d.contactPerson.push({
        name: "",
        phone: "",
        email: "",
      });
    } else {
      d.contactPerson = d.contactPerson.filter((v, i) => i !== ind);
    }
    setFormData(d);
  };
  const parentCallBackDocs = (ind, row) => {
    let d = JSON.parse(JSON.stringify(formData));
    d.docsRef[ind] = row;
  };
  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                Dealer Details
                <Link
                  style={{ float: "right" }}
                  className="btn btn-danger"
                  to="/dealer"
                >
                  Back
                </Link>
              </h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({
                          current: activeTab === 1,
                        })}
                        onClick={() => {
                          setactiveTab(1);
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">01</span> Dealer Details
                      </NavLink>
                    </NavItem>

                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        onClick={() => {
                          setactiveTab(2);
                        }}
                        disabled={!(passedSteps || []).includes(2) && !props.id}
                      >
                        <span className="number">02</span> Document Upload
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix mt-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          name: formData.name,
                          phone: formData.phone,
                          email: formData.email,
                          remarks: formData.remarks,
                          docsRef: formData.docsRef,
                          bankDetails: formData.bankDetails,
                          address1: formData.address.address1,
                          address2: formData.address.address2,
                          address3: formData.address.address3,
                          country: formData.address.country,
                          state: formData.address.state,
                          city: formData.address.city,
                          postalCode: formData.address.postalCode,
                          IFSC: formData.bankDetails.IFSC,
                          bankName: formData.bankDetails.bankName,
                          acName: formData.bankDetails.acName,
                          acNumber: formData.bankDetails.acNumber,
                          cpPhone: formData.contactPerson.mobile,
                          cpEmail: formData.contactPerson.email,
                          cpName: formData.contactPerson.name,
                          contactPerson: formData.contactPerson,
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required(
                            "Please Enter Dealer Name"
                          ),
                          email: Yup.string()
                            .email("Please enter a valid Email")
                            .required("Please enter a valid Email Address"),
                          phone: Yup.string()
                            .matches(
                              phoneRegExp,
                              "Please enter a valid Phone Number"
                            )
                            .required("Please enter a valid Phone Number"),
                        })}
                        onSubmit={values => {
                          //props.editProfile(values);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <Row md={3} lg={3} sm={1} xs={1}>
                              <Col>
                                <Label for="ame" className="form-label">
                                  Name *
                                </Label>
                                <Field
                                  name="name"
                                  type="text"
                                  placeholder="Name"
                                  values={formData.name}
                                  disabled={props.dealerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.name && touched.name
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Email *
                                </Label>
                                <Field
                                  name="email"
                                  type="email"
                                  maxLength="100"
                                  values={formData.email}
                                  disabled={props.dealerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  placeholder="Email"
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </Col>

                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Phone No. *
                                </Label>
                                <Field
                                  name="phone"
                                  type="number"
                                  maxLength="12"
                                  values={formData.phone}
                                  placeholder="Phone No."
                                  disabled={props.dealerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.phone && touched.phone
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Status
                                </Label>
                                <br />
                                <SwitchUI
                                  parentCallback={value => {
                                    handleChange("status", value ? 1 : 0);
                                  }}
                                  offText="Off"
                                  onText="On"
                                  checked={formData.status === 0 ? false : true}
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Remarks
                                </Label>
                                <Field
                                  as="textarea"
                                  name="remarks"
                                  maxLength="200"
                                  placeholder={"Remarks "}
                                  values={formData.remarks}
                                  disabled={props.dealerEditDisabled}
                                  onChange={e => {
                                    handleChange(e.target.name, e.target.value);
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.remarks && touched.remarks
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="remarks"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <h5
                              style={{ borderBottom: "1px solid #ccc" }}
                              className="mt-2 mb-2"
                            >
                              Address Details
                            </h5>
                            <Row md={3} lg={3} sm={1} xs={1}>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Address Line 1 *
                                </Label>
                                <Field
                                  name="address1"
                                  type="text"
                                  placeholder="Address Line 1 "
                                  values={formData.address.address1}
                                  disabled={props.dealerEditDisabled}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "address"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.address1 && touched.address1
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="address1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Address Line 2
                                </Label>
                                <Field
                                  name="address2"
                                  type="text"
                                  placeholder="Address Line 2 "
                                  values={formData.address.address2}
                                  disabled={props.dealerEditDisabled}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "address"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.address2 && touched.address2
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="address2"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Address Line 3
                                </Label>
                                <Field
                                  name="address3"
                                  type="text"
                                  placeholder="Address Line 3 "
                                  values={formData.address.address3}
                                  disabled={props.dealerEditDisabled}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "address"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.address3 && touched.address3
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="address3"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Country *
                                </Label>
                                <Select
                                  value={
                                    formData.address.countryId
                                      ? countryList[
                                          countryList.findIndex(
                                            a =>
                                              a.value ===
                                              formData.address.countryId
                                          )
                                        ]
                                      : null
                                  }
                                  options={countryList}
                                  onChange={e => {
                                    handleChange(
                                      "countryId",
                                      e.value,
                                      "address"
                                    );
                                  }}
                                  classNamePrefix="select2-selection"
                                  name="countryId"
                                  isDisabled={props.dealerEditDisabled}
                                />
                                <ErrorMessage
                                  name="countryId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  State *
                                </Label>
                                <Select
                                  value={
                                    formData.address.stateId
                                      ? stateList[
                                          stateList.findIndex(
                                            a =>
                                              a.value ===
                                              formData.address.stateId
                                          )
                                        ]
                                      : null
                                  }
                                  options={stateList}
                                  onChange={e => {
                                    handleChange("stateId", e.value, "address");
                                  }}
                                  classNamePrefix="select2-selection"
                                  name="stateId"
                                  isDisabled={props.dealerEditDisabled}
                                />
                                <ErrorMessage
                                  name="stateId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  City *
                                </Label>
                                <Select
                                  value={
                                    formData.address.cityId
                                      ? cityList[
                                          cityList.findIndex(
                                            a =>
                                              a.value ===
                                              formData.address.cityId
                                          )
                                        ]
                                      : null
                                  }
                                  options={cityList}
                                  onChange={e => {
                                    handleChange("cityId", e.value, "address");
                                  }}
                                  classNamePrefix="select2-selection"
                                  name="cityId"
                                  isDisabled={props.dealerEditDisabled}
                                />
                                <ErrorMessage
                                  name="cityId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  PostaL Code *
                                </Label>
                                <Field
                                  type="number"
                                  name="postalCode"
                                  maxLength="6"
                                  disabled={props.dealerEditDisabled}
                                  placeholder={"Postal Code "}
                                  values={formData.address.postalCode}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "address"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.postalCode && touched.postalCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="postalCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <h5
                              style={{ borderBottom: "1px solid #ccc" }}
                              className="mt-2 mb-2"
                            >
                              Bank Details
                            </h5>
                            <Row md={3} lg={3} sm={1} xs={1}>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Bank Name
                                </Label>
                                <Field
                                  type="text"
                                  name="bankName"
                                  maxLength="100"
                                  disabled={props.dealerEditDisabled}
                                  placeholder={"Bank Name "}
                                  values={formData.bankDetails.bankName}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "bankDetails"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.bankName && touched.bankName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="bankName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Account Holder Name
                                </Label>
                                <Field
                                  type="text"
                                  name="acName"
                                  maxLength="100"
                                  disabled={props.dealerEditDisabled}
                                  placeholder={"Account Holder Name"}
                                  values={formData.bankDetails.acName}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "bankDetails"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.acName && touched.acName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="acName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  Bank Account No
                                </Label>
                                <Field
                                  type="text"
                                  name="acNumber"
                                  maxLength="20"
                                  disabled={props.dealerEditDisabled}
                                  placeholder={"Bank Account No "}
                                  values={formData.bankDetails.acNumber}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "bankDetails"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.acNumber && touched.acNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="acNumber"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                              <Col className="pt-1">
                                <Label for="ame" className="form-label">
                                  IFSC Code
                                </Label>
                                <Field
                                  type="text"
                                  name="IFSC"
                                  maxLength="20"
                                  disabled={props.dealerEditDisabled}
                                  placeholder={"IFSC Code"}
                                  values={formData.bankDetails.IFSC}
                                  onChange={e => {
                                    handleChange(
                                      e.target.name,
                                      e.target.value,
                                      "bankDetails"
                                    );
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.IFSC && touched.IFSC
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="IFSC"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <h5
                              style={{ borderBottom: "1px solid #ccc" }}
                              className="mt-2 mb-2"
                            >
                              Contact Person
                            </h5>
                            {formData.contactPerson.map((v, i) => {
                              return (
                                <Row
                                  md={4}
                                  lg={4}
                                  sm={1}
                                  xs={1}
                                  className="mt-3"
                                  key={`cpinfo-${i}`}
                                >
                                  <Col>
                                    <Label for="ame" className="form-label">
                                      Contact Person Name *
                                    </Label>
                                    <Input
                                      name={`name${i}`}
                                      type="text"
                                      placeholder="Name"
                                      value={v.name}
                                      disabled={props.dealerEditDisabled}
                                      onChange={e => {
                                        handleChange(
                                          "name",
                                          e.target.value,
                                          "contactPerson",
                                          i
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.cpname && touched.cpname
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label for="ame" className="form-label">
                                      Contact Person Email *
                                    </Label>
                                    <Input
                                      name={`email${i}`}
                                      type="email"
                                      maxLength="100"
                                      value={v.email}
                                      disabled={props.dealerEditDisabled}
                                      onChange={e => {
                                        handleChange(
                                          "email",
                                          e.target.value,
                                          "contactPerson",
                                          i
                                        );
                                      }}
                                      placeholder="Email"
                                      className={
                                        "form-control" +
                                        (errors.cpemail && touched.cpemail
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Label for="ame" className="form-label">
                                      Contact Person Phone No. *
                                    </Label>
                                    <Input
                                      name={`phone${i}`}
                                      type="number"
                                      maxLength="12"
                                      value={v.phone}
                                      placeholder="Phone No."
                                      disabled={props.dealerEditDisabled}
                                      onChange={e => {
                                        handleChange(
                                          "phone",
                                          e.target.value,
                                          "contactPerson",
                                          i
                                        );
                                      }}
                                      className={
                                        "form-control" +
                                        (errors.cpphone && touched.cpphone
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                  </Col>
                                  <Col className="pt-4">
                                    <button
                                      className="btn btn-danger"
                                      disabled={i === 0 ? true : false}
                                      type="button"
                                      onClick={() => addDelCp("minus", i)}
                                      style={{ marginRight: "20px" }}
                                    >
                                      <i className="fas fa-minus" />
                                    </button>
                                    {i === formData.contactPerson.length - 1 ? (
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={() => addDelCp("add")}
                                      >
                                        <i className="fas fa-plus-circle" />
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Row>
                              );
                            })}
                            {!props.dealerEditDisabled ? (
                              <Row md={1}>
                                <Col className="text-center pt-2">
                                  <Button
                                    disabled={props.dealerEditDisabled}
                                    variant="success"
                                    className="btn btn-success"
                                    onClick={handleFormSubmitOfDealer}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </Form>
                        )}
                      </Formik>
                    </TabPane>
                    <TabPane tabId={2}>
                      <fieldset>
                        <legend> Document Upload</legend>
                        <DocumentUpload
                          underId={formData.id}
                          docsRef={formData.docsRef}
                          disabled={false}
                          type="DLR"
                          docTypes="dealer-docs"
                          parentCallBack={parentCallBackDocs}
                        />
                      </fieldset>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default IsLoadingHOC(AddEditDealer, "Wait .....");

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import AddEditDealer from "./AddEditDealer";
import DatatableTables from "../../components/Tables/DatatableTables";
import { getDealerListAction } from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Link, Redirect, useParams } from "react-router-dom";
import { getTokenDetails, parseJwt } from "../../helpers/common_helper";
const Patners = props => {
  const [columns, setColumns] = useState([]);
  const [listData, setListData] = useState([]);
  const [userTokenData, setUserTokenData] = useState({});
  const { type, id } = useParams();
  const pageNo = 1,
    pageLimit = 100;
  useEffect(() => {
    loadDealerList();
    let localData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    const accessToken = localData.accessToken ? localData.accessToken : "";
    if (accessToken) {
      let d = parseJwt(accessToken);
      setUserTokenData(d ? d : {});
    }
  }, []);

  const loadDealerList = () => {
    setListData([]);
    setColumns([]);
    props.setLoading(true);
    getDealerListAction(pageNo, pageLimit)
      .then(resp => {
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            if (Array.isArray(resp.data.rows)) {
              let data = resp.data.rows.map(v => {
                return {
                  id: v["_id"] ? v["_id"] : v.id,
                  name: v.name,
                  code: v.code,
                  email: v.email,
                  contact: v.contactPerson[0].name,
                  status: v.status,
                  phone: v.phone,
                };
              });
              setListData(data);
              setColumns([
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                },
                {
                  dataField: "code",
                  text: "Code",
                  sort: true,
                },
                {
                  dataField: "contact",
                  text: "Contact",
                  sort: true,
                },
                {
                  dataField: "email",
                  text: "Email",
                  sort: true,
                },
                {
                  dataField: "phone",
                  text: "Phone",
                  sort: true,
                },
                {
                  dataField: "id",
                  text: "Action",
                  formatter: ActionButtons,
                  sort: false,
                },
              ]);
            }
          }
        }
        props.setLoading(false);
      })
      .catch(err => {
        toast.error("Error in Dealer List load");
        console.log("....", err);
        props.setLoading(false);
      });
  };
  const ActionButtons = (cell, row, rowIndex, formatExtraData) => {
    let enableEdit = false;
    let tokendata = getTokenDetails();

    return (
      <Link
        title="Edit"
        className="text-success fa fa-edit"
        to={`/dealer/edit/${row.id}`}
      />
    );
  };
  if (type === "new" || type === "edit") {
    return (
      <React.Fragment>
        {!id && type === "edit" && userTokenData.DealerId ? (
          <Redirect to={`/dealer/edit/${userTokenData.DealerId}`} />
        ) : (
          ""
        )}
        <div className="page-content">
          <MetaTags>
            <title>LoanPeCar Admin Portal Add/Edit Dealer</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col className="col-12">
                <AddEditDealer dealerEditDisabled={false} id={id} />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>LoanPeCar Admin Portal Dealer</title>
          </MetaTags>

          <Container fluid>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      Dealer List
                      <Link
                        to="/dealer/new"
                        className="btn btn-success"
                        style={{ float: "right" }}
                      >
                        Add Dealer
                      </Link>
                    </CardTitle>
                    {columns.length > 0 ? (
                      <DatatableTables columns={columns} data={listData} />
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
};

export default IsLoadingHOC(Patners, "Wait .....");

import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Input, Label, Button, Form } from "reactstrap";
import IsLoadingHOC from "./IsLoadingHOC";
import { uploadDocsAction, getLookupListAction } from "../store/actions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const DocumentUpload = props => {
  const history = useHistory();
  const [documents, setDocuments] = useState([
    {
      docTypeId: "",
      docNumber: "",
      docRef: "",
      selectedFile: {},
      label: "Document Number",
    },
  ]);
  const [docTypeList, setDocTypeList] = useState([]);
  useEffect(() => {
    if (props.docsRef) {
      if (props.docsRef.length > 0) {
        let doc = [...props.docsRef];
        for (let i in doc) {
          if (doc[i].docTypeId) {
            let ind = docTypeList.findIndex(v => v.id === doc[i].docTypeId);
            if (docTypeList.length > 0) {
              doc[i]["label"] = docTypeList[ind].label;
            } else {
              doc[i]["label"] = "Document Number";
            }
          }
        }
        setDocuments(doc);
      }
    }
  }, [props.docsRef, docTypeList]);
  const handleChange = (ind, key, value, event) => {
    let doc = [...documents];
    if (key === "docTypeId") {
      let i = docTypeList.findIndex(v => v.id === value);
      doc[ind]["label"] = docTypeList[i].label;
    }
    if (key === "selectedFile" && value) {
      if (value.size >= 6291456) {
        event.target.value = null;
        toast.error("Please select image below 5MB");
        return true;
      }
    }
    doc[ind][key] = value;
    setDocuments(doc);
  };
  const handleFormSubmit = async ind => {
    let i = docTypeList.findIndex(v => v.id === documents[ind].docTypeId);
    let docTypes = docTypeList[i];
    if (docTypes.meta && docTypes.meta.regEx) {
      let regex = new RegExp(docTypes.meta.regEx);
      if (!regex.test(documents[ind].docNumber)) {
        toast.error(`Please enter valid ${docTypes.label} number`);
        return false;
      }
    }
    props.setLoading(true);
    let reqPayload = {
      docTypeId: documents[ind].docTypeId,
      docUrl: documents[ind].docUrl,
      docNumber: documents[ind].docNumber,
      refType: props.type,
      refId: props.underId,
      remarks: "",
      fileData: "",
    };
    if (documents[ind].selectedFile) {
      if (documents[ind].selectedFile.name) {
        reqPayload.fileData = await getBase64(documents[ind].selectedFile);
      }
    }
    uploadDoc(reqPayload, i)
      .then(resp => {
        props.docsRef.push(resp.data);
        props.setLoading(false);
        toast.success(` ${docTypes.label} updated successfully`);
      })
      .catch(err => {
        toast.error(err);
        props.setLoading(false);
      });
  };

  const getBase64 = file => {
    return new Promise((res, rej) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        res(reader.result);
      };
      reader.onerror = function (error) {
        rej(error);
      };
    });
  };
  const uploadDoc = (reqBody, ind) => {
    return new Promise((req, rej) => {
      uploadDocsAction(reqBody)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            req(resp);
            let rows = {
              docTypeId: resp.data.docTypeId,
              docNumber: resp.data.docNumber,
              docRef: resp.data.refId,
              selectedFile: { docUrl: resp.data.path },
              label: "Document Number",
              id: resp.data.id,
            };
            let dd = [...documents];
            dd[ind] = rows;
            setDocuments(dd);
            props.parentCallBack(ind, rows);
          } else {
            rej(
              "Something went wrong! Please try again or contact to Administration Department"
            );
          }
        })
        .catch(err => {
          props.setLoading(false);
          let msg =
            "Something went wrong! Please try again or contact to Administration Department";
          if (err.response) {
            if (err.response.data) {
              if (Array.isArray(err.response.data.errors)) {
                msg = "";
                for (let i in err.response.data.errors) {
                  msg = `${msg}
            ${
              err.response.data.errors[i].error_msg
                ? err.response.data.errors[i].error_msg
                : err.response.data.errors[i].message
            }`;
                }
              }
            }
          }
          rej(msg);
        });
    });
  };
  const addDelCp = (op, ind) => {
    let d = JSON.parse(JSON.stringify(documents));
    if (op === "add") {
      d.push({
        docTypeId: "",
        docNumber: "",
        docRef: "",
        selectedFile: {},
        label: "Document Number",
      });
    } else {
      d = d.filter((v, i) => i !== ind);
    }
    setDocuments(d);
  };
  useEffect(() => {
    getLookupListAction(props.docTypes)
      .then(d => {
        if (typeof d.data === "object")
          if (Array.isArray(d.data.rows)) {
            setDocTypeList(d.data.rows);
          }
      })
      .catch(er => {
        console.log("lokup service fail", er);
      });
  }, [props.type]);
  if (props.underId) {
    return (
      <Container fluid>
        {documents.map((v, i) => {
          return (
            <Row md={4} sm={2} xs={1} key={`doc-${i}`} className="mt-3">
              <Col>
                <Label for="ame" className="form-label">
                  Document Type *
                </Label>
                <select
                  className="form-select"
                  value={v.docTypeId}
                  onChange={e =>
                    handleChange(i, "docTypeId", e.target.value, e)
                  }
                >
                  <option value="">--Select--</option>
                  {docTypeList.map((d, j) => {
                    return (
                      <option key={`doc-type-${i}-${j}`} value={d.id}>
                        {d.label}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col>
                <Label for="ame" className="form-label">
                  {v.label}*
                </Label>
                <Input
                  type="text"
                  maxLength="50"
                  value={v.docNumber}
                  placeholder={v.label}
                  disabled={props.disabled}
                  onChange={e =>
                    handleChange(i, "docNumber", e.target.value, e)
                  }
                  className="form-control"
                />
              </Col>
              <Col>
                <Label for="ame" className="form-label">
                  Document Scan
                </Label>
                <Input
                  type="file"
                  onChange={e =>
                    handleChange(i, "selectedFile", e.target.files[0], e)
                  }
                  className="form-control"
                  accept="image/*,application/pdf"
                />
                {v.docUrl ? (
                  <img
                    src={v.docUrl}
                    height="80px"
                    className="my-3 text-center"
                    alt={v.label}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <Button
                  disabled={props.disabled}
                  variant="success"
                  className="btn btn-success mt-4"
                  onClick={e => handleFormSubmit(i)}
                >
                  Update
                </Button>
                <Button
                  className="btn btn-danger mt-4 mx-4"
                  disabled={i === 0 ? true : false}
                  type="button"
                  onClick={() => addDelCp("minus", i)}
                >
                  <i className="fas fa-minus" />
                </Button>
                {i === documents.length - 1 ? (
                  <Button
                    type="button"
                    className="btn btn-success mt-4"
                    onClick={() => addDelCp("add")}
                  >
                    <i className="fas fa-plus-circle" />
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          );
        })}
      </Container>
    );
  } else {
    return <h5>Document can add/edit only when Dealer is in edit mode</h5>;
  }
};

export default IsLoadingHOC(DocumentUpload, "Wait .....");

import { get, post, put } from "../../helpers/api_helper";
export const getCountryListAction = () => {
  return get(`/utility/country-list`);
};
export const getStateListAction = countryId => {
  return get(`/utility/state-list/${countryId}`);
};
export const getCityListAction = stateId => {
  return get(`/utility/city-list/${stateId}`);
};
export const getLookupListAction = type => {
  return get(`/lookup?type=${type}`);
};
export const uploadDocsAction = body => {
  return post(`/docMgr`, body);
};
export const getVehicalListAction = type => {
  return get(`/vehicle`);
};
export const searchPerosnAction = body => {
  return post(`/person/q`, body);
};
export const getPerosnDetailsAction = id => {
  return get(`/person/${id}`);
};
export const savePerosnDetailsAction = (method = "post", data, id) => {
  if (method === "post") {
    return post(`/person`, data);
  } else {
    return put(`/person/${id}`, data);
  }
};

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  getLookupListAction,
  saveTicketDetailsAction,
} from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import {
  TICKET_STATUS_LIST,
  TICKET_PRIORITY_LIST,
} from "../../constants/config";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import RichTextEditor from "../../components/RichTextEditor";
const NewTicket = props => {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
  let initialState = {
    id: props.id,
    subject: "",
    catgId: "",
    body: "",
    priority: 0,
    status: "open",
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    props.setLoading(true);
    getLookupListAction("Ticket")
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            setCategoryList(resp.data.rows);
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  }, [props.id]);

  const handleChange = (name, value) => {
    let fData = JSON.parse(JSON.stringify(formData));
    fData[name] = value;
    setFormData(fData);
  };

  const handleSaveBtnClick = async () => {
    if (formData.subject.trim().length < 1) {
      toast.error(`Please enter a Valid Subject`);
      return false;
    } else if (formData.catgId.length < 1) {
      toast.error(`Please select Category`);
      return false;
    } else if (parseInt(formData.priority) === 0) {
      toast.error(`Please select priority`);
      return false;
    } else if (formData.body.length < 10) {
      toast.error(`Please Enter Description`);
      return false;
    }
    props.setLoading(true);
    try {
      let fdata = JSON.parse(JSON.stringify(formData));
      fdata.catgId = parseInt(fdata.catgId);
      fdata.priority = parseInt(fdata.priority);
      if (!fdata.id) {
        delete fdata.id;
      }
      saveTicketDetailsAction(fdata, fdata.id)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            toast.success(
              `Ticket Created successfully and your ticket no:${
                resp.data.TicketId ? resp.data.TicketId.toUpperCase() : "_____"
              }`
            );
            setFormData(initialState);
            history.push("/support");
          } else {
            toast.error(
              "Something went wrong! Please try again or contact Administrator"
            );
          }
        })
        .catch(err => {
          props.setLoading(false);
          let msg =
            "Something went wrong! Please try again or contact Administrator";
          if (err.response) {
            if (err.response.data) {
              if (Array.isArray(err.response.data.errors)) {
                msg = "";
                for (let i in err.response.data.errors) {
                  msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
                }
              }
            }
          }
          toast.error(msg);
        });
    } catch (err) {
      props.setLoading(false);
      toast.error(typeof err === "string" ? err : "Something went wrong");
      console.log(".........", err);
    }
  };
  return (
    <>
      <MetaTags>
        <title>Help Center New Ticket | LoanPeCar Admin Portal</title>
      </MetaTags>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <CardTitle>
                New Ticket{" "}
                <Link
                  style={{ float: "right" }}
                  className="btn btn-danger"
                  to="/support"
                >
                  Back
                </Link>
              </CardTitle>
              <CardSubtitle className="mb-4">
                Fill all information below
              </CardSubtitle>

              <Form method="post" action="#">
                <Row className="pb-2" md={3} sm={2} xs={1}>
                  <Col className="pt-3">
                    <Label htmlFor="name">Subject *</Label>
                    <Input
                      id="subject"
                      name="subject"
                      type="text"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.subject}
                      maxLength={100}
                      placeholder="Subject"
                      className="form-control"
                    />
                  </Col>
                  <Col className="pt-3">
                    <Label className="control-label">Category *</Label>
                    <select
                      id="catgId"
                      name="catgId"
                      className="form-select select2"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.catgId}
                    >
                      <option value="">---Select Category---</option>
                      {categoryList.map((v, i) => {
                        return (
                          <option key={`title-${i}`} value={v.ID}>
                            {v.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col className="pt-3">
                    <Label className="control-label">Priority *</Label>
                    <select
                      name="priority"
                      id="priority"
                      className="form-select select2"
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      value={formData.priority}
                    >
                      <option value="">---Select Priority---</option>
                      {Object.keys(TICKET_PRIORITY_LIST).map((v, i) => {
                        return (
                          <option key={`title-${i}`} value={v}>
                            {TICKET_PRIORITY_LIST[v]}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>

                <Row className="pb-5" md={1} sm={1} xs={1}>
                  <Col>
                    <Label htmlFor="Description">Description *</Label>
                    <RichTextEditor
                      value="0"
                      parentCallback={handleChange}
                      id="body"
                    />
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    type="button"
                    onClick={handleSaveBtnClick}
                    color="success"
                    className="btn "
                  >
                    Create Ticket
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default IsLoadingHOC(NewTicket, "Wait .....");

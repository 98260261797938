import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  saveTicketDetailsAction,
  getTicketDetailsAction,
} from "../../store/actions";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  CardText,
  Col,
  Form,
  Alert,
  Label,
  Row,
} from "reactstrap";
import {
  TICKET_STATUS_LIST,
  TICKET_PRIORITY_LIST,
} from "../../constants/config";
import { useHistory, Link } from "react-router-dom";
import RichTextEditor from "../../components/RichTextEditor";
import { parseJwt } from "../../helpers/common_helper";
const TicketDetails = props => {
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
  const [clearEditorTestArea, setClearEditorTestArea] = useState(0);
  let initialState = {
    id: props.id,
    body: "",
    status: "open",
  };
  const [formData, setFormData] = useState(initialState);
  const initTicketDet = {
    attachments: null,
    body: "",
    catgId: 0,
    catgName: "",
    code: "",
    createdAt: "",
    details: { count: 0, rows: [] },
    priority: 0,
    status: "open",
    subject: "",
    updatedAt: "",
    userCode: "",
    userName: "",
  };
  const [ticketDet, setTicketsDet] = useState(initTicketDet);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    loadTicketDetails();
    let tData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};
    if (tData.accessToken) {
      let data = parseJwt(tData.accessToken);
      if (data.type === 0) {
        setIsAdmin(true);
      }
    }
  }, [props.id]);
  const loadTicketDetails = () => {
    props.setLoading(true);
    getTicketDetailsAction(props.id)
      .then(resp => {
        props.setLoading(false);
        if (resp.httpCode >= 200 && resp.httpCode <= 299) {
          if (resp.data) {
            let fd = JSON.parse(JSON.stringify(formData));
            fd.status = resp.data.status;
            setFormData(fd);
            setTicketsDet(resp.data);
          }
        }
      })
      .catch(err => {
        props.setLoading(false);
      });
  };
  const handleChange = (name, value) => {
    let fData = JSON.parse(JSON.stringify(formData));
    fData[name] = value;
    setFormData(fData);
  };

  const handleSaveBtnClick = async () => {
    if (formData.body.length < 10) {
      toast.error(`Please Enter Description`);
      return false;
    }
    props.setLoading(true);
    try {
      let fdata = JSON.parse(JSON.stringify(formData));
      fdata.categoryId = parseInt(fdata.categoryId);
      if (!fdata.id) {
        delete fdata.id;
      }
      saveTicketDetailsAction(fdata, fdata.id)
        .then(resp => {
          props.setLoading(false);
          if (resp.httpCode >= 200 && resp.httpCode <= 299) {
            toast.success("Message send successfully");
            setFormData(initialState);
            setClearEditorTestArea(clearEditorTestArea + 1);
            loadTicketDetails();
          } else {
            toast.error(
              "Something went wrong! Please try again or contact Administrator"
            );
          }
        })
        .catch(err => {
          props.setLoading(false);
          let msg =
            "Something went wrong! Please try again or contact Administrator";
          if (err.response) {
            if (err.response.data) {
              if (Array.isArray(err.response.data.errors)) {
                msg = "";
                for (let i in err.response.data.errors) {
                  msg = `${msg}
              ${
                err.response.data.errors[i].error_msg
                  ? err.response.data.errors[i].error_msg
                  : err.response.data.errors[i].message
              }`;
                }
              }
            }
          }
          toast.error(msg);
        });
    } catch (err) {
      props.setLoading(false);
      toast.error(typeof err === "string" ? err : "Something went wrong");
      console.log(".........", err);
    }
  };
  return (
    <>
      <MetaTags>
        <title>
          Ticket {ticketDet.code.toUpperCase()} Details | LoanPeCar Admin Portal
        </title>
      </MetaTags>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <CardTitle>
                Ticket Information{` ${ticketDet.code.toUpperCase()}`}
                <Link
                  style={{ float: "right" }}
                  className="btn btn-danger"
                  to="/support"
                >
                  Back
                </Link>
              </CardTitle>
              <CardSubtitle className="mb-4">
                Check all information below
              </CardSubtitle>
              <Row md={1} sm={1} xs={1} className="border border-success">
                <Col className="pb-3">
                  <Row md="4" xs="2" sx="2">
                    <Col className="pt-2">
                      <strong>Subject:</strong>
                    </Col>
                    <Col className="pt-2">{ticketDet.subject}</Col>
                    <Col className="pt-2">
                      <strong>Category:</strong>
                    </Col>
                    <Col className="pt-2">{ticketDet.catgName}</Col>
                    <Col className="pt-2">
                      <strong>Priority:</strong>
                    </Col>
                    <Col className="pt-2">
                      {TICKET_PRIORITY_LIST[ticketDet.priority]}
                    </Col>
                    <Col className="pt-2">
                      <strong>Status:</strong>
                    </Col>
                    <Col className="pt-2">
                      {TICKET_STATUS_LIST[ticketDet.status]}
                    </Col>
                    <Col className="pt-2">
                      <strong>Created By:</strong>
                    </Col>
                    <Col className="pt-2">{`${
                      ticketDet.userName
                    } (${ticketDet.userCode.toLocaleUpperCase()})`}</Col>
                    <Col className="pt-2">
                      <strong>Created At:</strong>
                    </Col>
                    <Col className="pt-2">
                      {ticketDet.createdAt
                        ? new Date(ticketDet.createdAt).toLocaleString()
                        : ""}
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col md="3" xs="12" sm="12">
                      <strong>Description</strong>
                    </Col>
                    <Col
                      md="9"
                      xs="12"
                      sm="12"
                      dangerouslySetInnerHTML={{
                        __html: ticketDet.body,
                      }}
                    />
                  </Row>
                </Col>
                <p className="border border-success" />
                <Col>
                  {ticketDet.details.rows.map((v, i) => {
                    return (
                      <Row
                        key={`reply-${v.code}`}
                        className={
                          isAdmin
                            ? !v.isClientResponse
                              ? "justify-content-end"
                              : "justify-content-start"
                            : v.isClientResponse
                            ? "justify-content-end"
                            : "justify-content-start"
                        }
                      >
                        <Col xs lg="8">
                          <Card>
                            <CardBody>
                              <CardTitle>
                                <Alert
                                  color={
                                    isAdmin
                                      ? !v.isClientResponse
                                        ? "success"
                                        : "warning"
                                      : v.isClientResponse
                                      ? "success"
                                      : "warning"
                                  }
                                >
                                  {`${
                                    v.userName
                                  } (${v.userCode.toUpperCase()})`}
                                  <small style={{ float: "right" }}>
                                    {v.isClientResponse
                                      ? "Customer"
                                      : "Support Team"}
                                  </small>
                                </Alert>
                              </CardTitle>
                              <CardText
                                dangerouslySetInnerHTML={{
                                  __html: v.body,
                                }}
                              />
                              <CardSubtitle className="mb-2 text-muted">
                                <span style={{ float: "left" }}>
                                  <strong>Message Code: </strong>
                                  {v.code.toUpperCase()}
                                </span>
                                <span style={{ float: "right" }}>
                                  {new Date(v.createdAt).toLocaleString()}
                                </span>
                              </CardSubtitle>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <p className="border border-success" />
                <Col>
                  <h5 className="pb-2">
                    Reply on Ticket{" "}
                    <strong> {ticketDet.code.toUpperCase()}</strong>
                  </h5>
                  <Form method="post" action="#">
                    <Row className="pb-2" md={1} sm={1} xs={1}>
                      <Col>
                        <Label htmlFor="Description">Description *</Label>
                        <RichTextEditor
                          parentCallback={handleChange}
                          id="body"
                          value={clearEditorTestArea}
                        />
                      </Col>
                    </Row>
                    <Row className="pb-2" md={3} sm={2} xs={1}>
                      <Col>
                        <Label className="control-label">Sataus *</Label>
                        <select
                          name="status"
                          id="priority"
                          className="form-select select2"
                          onChange={e => {
                            handleChange(e.target.name, e.target.value);
                          }}
                          value={formData.status}
                        >
                          {Object.keys(TICKET_STATUS_LIST).map((v, i) => {
                            return (
                              <option key={`title-${i}`} value={v}>
                                {TICKET_STATUS_LIST[v]}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    </Row>
                    <div className="text-center pb-3">
                      <Button
                        type="button"
                        onClick={handleSaveBtnClick}
                        color="success"
                        className="btn "
                      >
                        Reply
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default IsLoadingHOC(TicketDetails, "Wait .....");

import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import IsLoadingHOC from "./IsLoadingHOC";
import { tokenStatusAction, refreshTokenAction } from "../store/actions";
const Timer = props => {
  const [timeLeft, setTimeLeft] = useState(50000);
  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  useEffect(() => {
    checkTokenStatus();
  }, []);
  const checkTokenStatus = () => {
    tokenStatusAction()
      .then(data => {
        if (data.data.timeRemaining < 1) {
          setTimeLeft(0);
        } else {
          setTimeLeft(data.data.timeRemaining * 60);
        }
      })
      .catch(ex => {
        setTimeLeft(0);
      });
  };
  const tokenRefreshHandler = () => {
    let tData = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : {};

    refreshTokenAction({ refreshToken: tData.refreshToken })
      .then(async resp => {
        localStorage.setItem(
          "authUser",
          JSON.stringify(Object.assign(tData, resp.data))
        );
        await new Promise(resolve => setTimeout(resolve, 2000));
        checkTokenStatus();
      })
      .catch(ex => {});
  };
  if (timeLeft < 300 && timeLeft > 0) {
    return (
      <Alert color="danger">
        You will be logout in {Math.floor(timeLeft / 60)} minute{" "}
        {timeLeft - Math.floor(timeLeft / 60) * 60} Second{". "}
        <Link
          type="button"
          style={{ fontWeight: "bold", textDecoration: "underline !important" }}
          onClick={tokenRefreshHandler}
        >
          Continue Session
        </Link>
      </Alert>
    );
  } else if (timeLeft <= 0) {
    return <Redirect to="/logout" />;
  } else return <></>;
};

export default IsLoadingHOC(Timer, "Wait .....");

import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";
import ChangePassword from "../pages/Authentication/ChangePassword";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Dealer
import Dealer from "../pages/Dealer";
import LoanApplication from "../pages/LoanApplication";
import Reports from "../pages/Reports";
import Support from "../pages/Support";
import Inbox from "../pages/Inbox";
const authProtectedRoutes = [
  { path: "/dashboard", exact: true, component: Dashboard },
  //Dealer
  { path: "/dealer/:type/:id", exact: true, component: Dealer },
  { path: "/dealer/:type", exact: true, component: Dealer },
  { path: "/dealer", exact: true, component: Dealer },

  //Loan Application
  { path: "/loanfile/:type/:id", exact: true, component: LoanApplication },
  { path: "/loanfile/:type", exact: true, component: LoanApplication },
  { path: "/loanfile", exact: true, component: LoanApplication },

  //Reports
  { path: "/reports/:id", exact: true, component: Reports },
  //Support
  { path: "/support/:type/:id", exact: true, component: Support },
  { path: "/support/:type", exact: true, component: Support },
  { path: "/support", exact: true, component: Support },
  { path: "/inbox", exact: true, component: Inbox },
  { path: "/inbox/:id", exact: true, component: Inbox },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/change-password", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
];

export { authProtectedRoutes, publicRoutes };

//import { GET_TASKS, GET_TASKS_FAIL, GET_TASKS_SUCCESS } from "./actionTypes"
import { get, post, put } from "../../helpers/api_helper";
export const getLoanApplicationListAction = (pageno = 1, limit = 10) => {
  return get(`/application?page=${pageno}&limit=${limit}`);
};
export const getLoanApplicationDetailsAction = id => {
  return get(`/application/${id}`);
};
export const saveLoanApplicationDetailsAction = (method = "post", data, id) => {
  if (method === "post") {
    return post(`/application`, data);
  } else {
    return put(`/application/${id}`, data);
  }
};
